import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Us } from "../components/Macki/Us";
import { Comets } from "../components/Macki/Comet";
import { useNavigate } from "react-router-dom";
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const page2Variant = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 1, transition: { delay: 0.79 } },
};

const imgVariant = {
  initial: { scale: 0.85, y: 200, x: 0, opacity: 1 },
  animate: { scale: 1.6, y: 340, x: 0, opacity: 0.8 },
};

const fadeVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 0.99 },
};

const nameVariant = {
  animate: (i) => ({
    transition: {
      delayChildren: 0.55,
      staggerChildren: 0.12,
      staggerDirection: i,
    },
  }),
  exit: (i) => ({
    transition: {
      staggerChildren: 0.08,
      staggerDirection: i,
    },
  }),
};

const letterVariant = {
  initial: { opacity: 0, y: "120%" },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 1, y: "120%" },
};

const Code = () => {
  const navigation = useNavigate();
  const [code, setCode] = useState("");
  const [inputVal, setInputVal] = useState([]);
  const [correct, setCorrect] = useState(false);
  //   const inputVal = [];
  useEffect(() => {
    function OTPInput() {
      const inputs = document.querySelectorAll("#otp > *[id]");

      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener("keydown", function (event) {
          if (event.key === "Backspace") {
            if (i !== 0) inputs[i - 1].focus();
          } else {
            if (i === inputs.length - 1 && inputs[i].value !== "") {
              setInputVal([]);
              inputs.forEach((input) => {
                setInputVal((prev) => [...prev, input.value]);
              });

              return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              setInputVal([]);
              event.preventDefault();
            }
          }
        });
      }
    }
    OTPInput();
  }, []);
  useEffect(() => {
    console.log(inputVal);
    setCode(inputVal.slice(0, 6).join(""));
  }, [inputVal]);
  const checlCorrectCode = (code) => {
    const date = new Date();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(2);
    const day = ("0" + date.getDate()).slice(-2);
    const monthYear = `${day}${month}${year}`;
    console.log(monthYear);
    if (code === monthYear) {
      setCorrect(true);
      navigation("/main");
    } else {
      setCorrect(false);
      const inputs = document.querySelectorAll("#otp > *[id]");
      setCode("");
      setInputVal([]);
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = "";
        inputs[0].focus();
      }
    }
  };
  useEffect(() => {
    if (code) checlCorrectCode(code);
  }, [code]);

  return (
    <motion.main
      variants={page2Variant}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ ...transition }}
      className="bg-background h-screen relative overflow-hidden w-full flex justify-center items-center"
    >
      <motion.div
        variants={imgVariant}
        initial="initial"
        animate="animate"
        transition={{ duration: 1.1, ease: [0.91, 0.09, 0.47, 0.89] }}
        className="max-w-full overflow-hidden pointer-events-none absolute"
        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <div className="relative sky ">
          <Comets style={{ position: "absolute", pointerEvents: "none" }} />
          <Us filter="drop-shadow(0px 12px 32px rgba(0,16,36,0.5))" />
        </div>
        {/* <img src={IMAGE_URL} alt="Woman leaning on wall" /> */}
      </motion.div>

      <div className="flex justify-center items-center">
        {!correct && (
          <motion.div
            className="mb-6 text-center"
            variants={fadeVariant}
            initial="initial"
            animate="animate"
            transition={{ delay: 1.8, ...transition }}
          >
            <h3 className="flex justify-center text-2xl text-white font-bold">
              Add meg a kodot
            </h3>
            <div id="otp" className="flex justify-center items-center">
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="first"
                maxLength="1"
              />
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="second"
                maxLength="1"
              />
              <span className="font-bold text-xl text-white">/</span>
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="third"
                maxLength="1"
              />
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="fourth"
                maxLength="1"
              />
              <span className="font-bold text-xl text-white">/</span>
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="fifth"
                maxLength="1"
              />
              <input
                className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline"
                type="text"
                id="sixth"
                maxLength="1"
              />
            </div>
          </motion.div>
        )}
      </div>
      {/* <motion.p
        variants={fadeVariant}
        initial="initial"
        animate="animate"
        transition={{ delay: 4, ...transition }}
        className="bio"
      >
        Never to was first make hard and out consider walls or he young quickly
        shall pattern. So values, it a live been guard how exploration, pay
        boss's to more armour he way drew them an side have and attempt. And
        have devious every embarrassed had same in out yards is counter.
        Failures founding the their economics, mountains, indeed class. Wrong
        the go away for necessary been the position. A and still their violin
        been as to vices subjective the poured wait work the I day. The have
        wanted plans. A came handwriting screen extended regretting week trusted
        rank her high. Also that understand percussion has although communicated
        evaluate small disciplined of Mr. His standpoint fundamental; Her with
        was a history based which notch flows too your home, of talk is and way
        have frequency he not view sofas much had stage long I clothes
        systematic step, let's the need in the she self-interest from what
        avoided the part for any an of not tones the to appointed each here's
        the myself he don't for that didn't as sofa after title of attempt. Even
        human of to switching and appointed and moving their lose time behind
        mail himself impenetrable attempt, then. So values, it a live been guard
        how exploration, pay boss's to more armour he way drew them an side have
        and attempt. And have devious every embarrassed had same in out yards is
        counter. Failures founding the their economics, mountains, indeed class.
        Wrong the go away for necessary been the position. A and still their
        violin been as to vices subjective the poured wait work the I day. The
        have wanted plans. A came handwriting screen extended regretting week
        trusted rank her high. Also that understand percussion has although
        communicated evaluate small disciplined of Mr. His standpoint
        fundamental; Her with was a history based which notch flows too your
        home, of talk is and way have frequency he not view sofas much had stage
        long I clothes systematic step, let's the need in the she self-interest
        from what avoided the part for any an of not tones the to appointed each
        here's the myself he don't for that didn't as sofa after title of
        attempt. Even human of to switching and appointed and moving their lose
        time behind mail himself impenetrable attempt, then.
      </motion.p>
      <motion.div
        variants={fadeVariant}
        initial="initial"
        animate="animate"
        transition={{ delay: 1.5, ...transition }}
        className="btn-container"
      ></motion.div> */}
    </motion.main>
  );
};

export default Code;
