import React from "react";
import { motion } from "framer-motion";

export const Us = (props) => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 211.7 211.7"
      className="w-full my-0
      mx-auto"
      {...props}
    >
      <defs>
        <linearGradient id="a">
          <stop offset="0" stopColor="#d373f3" />
          <stop offset="1" stopColor="#0b4562" />
        </linearGradient>
        <linearGradient id="b">
          <stop offset="0" stopColor="#004986" />
          <stop offset="1" stopColor="#6454e5" />
        </linearGradient>
        <linearGradient
          href="#a"
          id="d"
          x1="55.9"
          x2="79.2"
          y1="36.9"
          y2="89.2"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          href="#a"
          id="e"
          x1="135.9"
          x2="155.3"
          y1="50.1"
          y2="95.4"
          gradientUnits="userSpaceOnUse"
        />
        <linearGradient
          href="#b"
          id="c"
          x1="131.8"
          x2="93.4"
          y1="76.9"
          y2="16.1"
          gradientUnits="userSpaceOnUse"
        />
        <radialGradient id="firelight">
          <stop offset="0" stopColor="#ff6584"></stop>
          <stop offset="0.3" stopColor="#f9a825"></stop>
          <stop offset="1" stopColor="#07647f"></stop>
        </radialGradient>
      </defs>
      <path
        fill="#07647f"
        d="m205.7 125.3-94.8-56a9 9 0 0 0-9.9 0l-94.7 56c-3.4 2-3 7.5 0 10l94.7 74.6c3 2.4 6.9 2.4 10 0l94.7-74.7c3-2.4 3.3-7.9 0-9.9z"
      />
      <g transform="matrix(0.29723, 0, 0, 0.29723, 15.7, 83.900002)" />
      <path
        fill="url(#c)"
        d="M111 17.8a8 8 0 0 0-7.4-5c-3-.1-2.5 4.6-3.9 7.9 0 0-2.4 3.2-3.2 5.1-10.4 24-29.3 74.8-29.3 74.8l-4 8.7v2.4l85.3.4v-3.4s-19.4-58.8-34.8-84a60.9 60.9 0 0 1-2.8-6.9z"
      />
      <path
        fill="url(#d)"
        d="M69.3 47.4c-.8-6.4-2.5-12.4-4-12.9-1.3-.4-4 4-5.5 8.5l-17.7 61-2.4 6.9v1.8l53.3.3v-2.6s-22.9-56.7-23.7-63z"
      />
      <path
        fill="#c5dbed"
        d="M69 42.5c-1-2.7-2.4-8-4-8-2.1 0-4.5 3.7-6 8.2-1.4 4.5-.6 5.4-1.3 6.6-1 1.9-1.9 3-1 4.1.2.3 2.1 0 2.4 0 1 0 2-1.4 3.3-1.4 1.2 0 1.3.7 2.5 1 1.1 0 2.1-2.7 3.1-2.7s1.7 2.3 2.2 3c.5.7 1.2 0 1.2-.3-.4-2.5.3-1.5-1.4-6.4 0 0 0-1.1-1-4.1z"
      />
      <path
        fill="url(#e)"
        d="M152.5 52.6c-1.1-2.1-2.1-11.4-8.7-7.4 0 0-2 3.1-3.5 7.6-5.9 16.8-21 51.9-21 51.9-1.3 3-2.8 5.8-2.8 5.8v1.5l61.2.3v-2.2s-24.1-55.3-25.2-57.5z"
      />
      <path
        fill="#c5dbed"
        d="M153.6 55c-.2-6.5-4-12.1-6.3-12-3 0-6.3 4.5-8.4 10-2 5.5-1 6.6-2 8.2-1.4 2.2-2.6 3.6-1.4 5 .3.4 3 0 3.4 0 1.5 0 3-1.8 4.7-1.8 1.8 0 1.9 1 3.6 1.2 1.5.2 3-3.3 4.4-3.2 1.2 0 2.3 2.7 3 3.7.7.8 3.6-1 3.6-1.3a26 26 0 0 0-3.3-7.7zm-58-28.6C98 21.8 101.2 12 103 12c1.4 0 5.2.6 6.5 2.7 1 1.8 2.2 4 3 6.4 1.7 5.4 3.7 6 4.5 7.5 1.1 2.2 2.8 4.4 1.4 5.4-2.5 1.8-5 .4-5.4 0-1-1-2-1.9-3.3-1.9-1.5 0-2 2.7-3.5 2.7-3 0-2.4-8.8-3.6-8.8-1 .1-2.2 7.2-3 7.7-.8.6-6.2 1.8-6.6.4-.9-3 .3-3.2 2.6-7.8z"
      />
      <motion.ellipse
        cx="103.5"
        cy="172"
        rx="20"
        ry="10"
        fill="url(#firelight)"
        opacity="0.5"
        animate={{ scaleX: 0.5, scaleY: 0.8, opacity: 0.3 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
      ></motion.ellipse>
      <path
        fill="#1c2045"
        d="M104.4 172.3a.9.9 0 0 0-1-.8.9.9 0 0 0-.8.7l-1 4.3a1.7 1.7 0 0 0 0 .4c0 1 .9 1.6 1.9 1.6 1 0 1.8-.7 1.8-1.6a1.8 1.8 0 0 0 0-.5zm6.6 1.9a2 2 0 0 0-.2-.2l-3.4-2.3a1 1 0 0 0-1 0 .7.7 0 0 0 0 .8l2.5 3.4a1.8 1.8 0 0 0 .2.2c.4.5 1.4.5 2 0 .5-.5.5-1.5 0-1.9zm2.6-3.5h-3.4a.4.4 0 0 0-.5.4.5.5 0 0 0 .4.5l3.2 1c.5 0 1-.3 1-.9 0-.6-.3-1-.7-1zm-16.3.4a.4.4 0 0 0-.4-.4h-3.5c-.4 0-.8.5-.8 1s.7 1 1.1.9l3.3-1a.4.4 0 0 0 .3-.5zm2.4.6-3.4 2.3a1.6 1.6 0 0 0-.3.2 1.3 1.3 0 0 0 0 1.8 1.4 1.4 0 0 0 2 0 1.5 1.5 0 0 0 .1-.1l2.6-3.4a.6.6 0 0 0-.1-.8.7.7 0 0 0-1 0z"
      />
      <path
        fill="#3f3d56"
        d="M105 167a.4.4 0 0 0 0-.1c0-.4-.8-.7-1.8-.7s-1.9.3-1.9.7a.3.3 0 0 0 0 .1l1 1.9c0 .1.4.3.9.3.4 0 .8-.2.9-.4z"
      />
      <path
        fill="#1c2045"
        d="M110.8 167.2a3 3 0 0 0-2 0 1.6 1.6 0 0 0-.2 0l-2.6 1.5c-.1.1 0 .3.1.4a1.5 1.5 0 0 0 1 0l3.4-1a2.9 2.9 0 0 0 .2-.1c.6-.2.6-.6 0-.8zm3.2 1.8c0-.2-.5-.3-1-.3l-3.2.4-.4.2c0 .1.2.2.5.2h3.4c.4 0 .8-.2.8-.4zm-17.3.1-3.3-.4c-.4 0-1 .1-1 .4 0 .2.3.4.7.4h3.5c.2 0 .4 0 .4-.2l-.3-.2zm3.6-.1c.2 0 .3-.2.1-.3l-2.6-1.4a1.3 1.3 0 0 0-.1 0 3 3 0 0 0-2 0c-.6.2-.5.5 0 .7a2.2 2.2 0 0 0 .3 0l3.4 1a1.5 1.5 0 0 0 .9 0z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1 }}
        animate={{ scaleY: 1.13 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        fill="#f9a825"
        d="M110.1 166.8c0 5.2-3 5.3-6.5 5.3-3.7 0-9.6-1-6.6-5.3 3.2-4.6 8.6-13.3 6.6-13.7 0 0 6.5 8.4 6.5 13.7z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1 }}
        animate={{ scaleY: 1.66 }}
        transition={{
          delay: 0.2,
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        fill="#ff6584"
        d="M105.9 170c0 2.1-1.2 2.1-2.6 2.1-1.5 0-3.8-.4-2.6-2 1.2-1.9 3.4-5.3 2.6-5.5 0 0 2.6 3.3 2.6 5.4z"
        opacity=".5"
      />
      <path
        fill="#0f5236"
        d="M 57.821 115 L 82.221 115 C 82.817 113.181 82.817 111.219 82.221 109.4 C 81.221 106.9 78.221 105.4 75.821 106.4 C 75.225 106.655 74.707 107.069 74.321 107.6 C 73.421 105.9 71.721 104.6 69.821 104.6 C 67.421 104.6 65.421 106.6 64.821 109 C 63.721 107.2 61.621 105.9 59.621 106.4 C 56.221 107.4 55.121 112.8 57.921 115 L 57.821 115 Z"
      />
      <path
        fill="#2a7056"
        d="M 41.014 115.2 L 60.714 115.2 C 61.209 113.741 61.209 112.159 60.714 110.7 C 59.914 108.8 57.414 107.6 55.514 108.4 C 55.033 108.622 54.619 108.967 54.314 109.4 C 53.633 108.011 52.258 107.094 50.714 107 C 48.714 107 47.114 108.6 46.514 110.4 C 45.714 109 44.014 108 42.314 108.4 C 39.614 109.1 38.714 113.4 41.014 115.2 L 41.014 115.2 Z M 80.614 115.2 L 96.814 115.2 C 97.114 113.6 97.214 112.1 96.814 110.7 C 96.114 108.7 94.114 107.6 92.514 108.4 C 92.114 108.614 91.769 108.96 91.514 109.4 C 90.914 108 89.814 106.9 88.514 106.9 C 86.914 106.9 85.614 108.6 85.214 110.4 C 84.514 109 83.214 107.9 81.814 108.4 C 79.514 109.1 78.814 113.4 80.614 115.2 Z"
      />
      <g fill="#075269">
        <path d="M112 199.9s-1 3.7-4.4 5.7v-.1c3.1-2 4.2-5.6 4.3-5.6z" />
        <path d="M106.9 204.6a1.3 1.7 40.2 0 1 1.8 2c-.6.7-3 2-3.4 1.5-.5-.5 1-2.8 1.6-3.5zm2.4-3.5c1-.4 2 .1 2 .1s-.6 1.3-1.6 1.8c-1.1.4-2.1-.1-2.1-.1s.7-1.3 1.7-1.8zm2.1.1s1 1.6.3 3.7h-.1c.7-2-.3-3.6-.3-3.6z" />
        <path d="M110.9 205a.8 1 40.2 0 1 1.8-.3c0 .5-.6 2-1.1 2-.5.2-.8-1.2-.7-1.7zm-.4-2.1c.4-.7 1.1-1 1.1-1s.3.7 0 1.3c-.4.7-1.2 1-1.2 1s-.2-.7.1-1.3z" />
      </g>
      <motion.g
        fill="#5d976e"
        style={{ originX: 0.5, originY: 1, skewX: 8 }}
        animate={{ skewX: -8 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
      >
        <path d="M112.1 200s1.8-5.4-1.3-10.2l-.3.2c3 4.7 1.3 9.8 1.3 9.9z" />
        <path d="M108.8 190.7a2.4 2.4 0 0 0 4.6-1.4c-.3-1.3-2.5-4.5-3.8-4.1-1.3.4-1.2 4.2-.8 5.5zm.2 6c1.3 1.2 3.4 1.2 3.4 1.2s.1-2-1-3.4c-1.2-1.2-3.3-1.2-3.3-1.2s-.2 2.1 1 3.4z" />
        <path d="M112.4 197.9s3-1.3 4-4.6h-.2a7.5 7.5 0 0 1-4 4.4z" />
        <path d="M115.2 192.7a1.5 1.5 0 0 0 2.5 1.6c.4-.7 1-3 .3-3.4-.6-.4-2.3 1.1-2.8 1.8zm-2.5 2.5c-.2 1 .7 2 .7 2s1-.8 1.1-1.8c.1-1-.7-2-.7-2s-1 .8-1.1 1.8z" />
      </motion.g>

      <motion.path
        animate={{ opacity: 1, scale: 1.005 }}
        transition={{
          ease: "linear",
          duration: 2,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        opacity="0.5"
        fill="#ffe04c"
        d="M41.2 31c-.6 0-1-3.6-1.4-4-.4-.6-3.5-1-3.5-1.6 0-.8 3-1.1 3.5-1.6.4-.5.8-4.2 1.4-4.2.6 0 .9 3.7 1.3 4.2s3.5.8 3.5 1.6c0 .7-3 1-3.5 1.5-.4.5-.7 4.2-1.3 4.2zm106.2-11c-.6 0-.9-3.7-1.3-4.2s-3.5-.9-3.5-1.6c0-.7 3-1 3.5-1.6.4-.5.7-4 1.3-4 .6 0 1 3.5 1.4 4 .4.5 3.5 1 3.5 1.6 0 .7-3.1 1.1-3.5 1.6-.5.5-.8 4.1-1.4 4.1zM76.2 32.5c-.4 0-.6-2.5-1-2.8-.2-.3-2.3-.6-2.3-1 0-.5 2-.8 2.4-1.1.3-.4.5-2.9.9-2.9s.6 2.5 1 2.8c.2.4 2.3.6 2.3 1.1 0 .5-2 .8-2.4 1.1-.3.3-.5 2.8-.9 2.8zm55.9 8.2c-.4 0-.6-2.4-1-2.8-.2-.3-2.3-.5-2.3-1s2-.8 2.4-1.1c.2-.4.4-2.8.9-2.8.4 0 .6 2.4.9 2.8.3.3 2.4.6 2.4 1 0 .5-2.1.8-2.4 1.1-.3.4-.5 2.8-.9 2.8zM87.8 13.8c-.5 0-.8-3-1.2-3.4-.3-.4-3-.7-3-1.3 0-.6 2.6-1 3-1.4.4-.4.6-3.5 1.1-3.5.5 0 .8 3 1.2 3.5.3.4 3 .7 3 1.3 0 .6-2.7 1-3 1.4-.4.4-.6 3.4-1.1 3.4zm74.6 27.5c.5 0 .9-3 1.3-3.4.3-.5 3-.7 3-1.3 0-.6-2.6-1-3-1.4-.3-.4-.4-3.5-1-3.5-.4 0-.8 3-1.2 3.4-.4.4-3 .6-3 1.2s2.6 1 2.9 1.5c.3.4.5 3.5 1 3.5z"
      />
      <motion.circle
        cx="58.5"
        cy="11"
        r=".8"
        fill="#ffe04c"
        animate={{ opacity: 1 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        opacity="0.2"
      />
      <motion.circle
        cx="89.7"
        cy="23.6"
        r=".8"
        fill="#ffe04c"
        animate={{ opacity: 1 }}
        transition={{
          ease: "linear",
          duration: 2,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.3,
        }}
        opacity="0.2"
      />
      <motion.circle
        cx="120.4"
        cy="13.2"
        r=".8"
        fill="#ffe04c"
        animate={{ opacity: 1 }}
        transition={{
          ease: "linear",
          duration: 1,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 1,
        }}
        opacity="0.2"
      />
      <motion.circle
        cx="145"
        cy="34.4"
        r=".8"
        fill="#ffe04c"
        animate={{ opacity: 1 }}
        transition={{
          ease: "linear",
          duration: 2,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        opacity="0.5"
      />
      <motion.circle
        cx="164.8"
        cy="15.3"
        r=".8"
        fill="#ffe04c"
        animate={{ opacity: 1 }}
        transition={{
          ease: "linear",
          duration: 2,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.7,
        }}
        opacity="0.45"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: -3 }}
        animate={{ skewX: 2.5 }}
        transition={{
          ease: "linear",
          duration: 6,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        fill="#0f5236"
        d="M 127.6 82.4 C 125.8 82.5 123.9 80.4 123.9 80.4 C 123.9 80.4 126.9 78.4 127.9 76.4 C 128.7 74.8 129.3 72.5 129.3 72.5 C 127.8 72.5 126.5 70.5 126.5 70.5 C 126.4 70.9 133.3 64.7 132 64.5 C 130.7 64.2 130.2 62.9 130.2 62.9 C 130.2 62.9 132.3 61.9 132.8 61.2 C 133.523 59.951 134.062 58.603 134.4 57.2 C 135.9 58.9 135.8 60.2 136.9 61.2 L 140.2 63.6 C 140.2 63.6 139.7 64.6 138.4 65 C 137.1 65.4 143.2 70.7 143.2 70.7 C 143.2 70.7 141 72.5 139.9 71.8 C 139.9 71.8 139.9 73.4 141.7 76.6 C 142.716 78.119 143.889 79.527 145.2 80.8 C 144.2 82.4 143 82.7 141.5 82.7 C 142.4 87.3 147.7 90.7 147.7 90.7 C 147.7 90.7 145.3 92.7 143.9 92.7 C 143 92.8 142.3 92.4 141.4 91.9 C 141.4 92.4 140.3 93.2 139.5 93.2 C 138.8 93.3 138.3 92.9 137.5 92.4 C 137.5 93.2 136.5 93.6 135.9 93.4 C 136.2 99 135.9 106.9 135.7 115.2 L 134.9 115.2 L 134.9 93.7 C 134.7 93.8 133.6 94.5 133.1 94.4 C 132.5 94.3 132.2 94.5 131.7 93.2 C 131.2 93.8 130.1 94.4 129.2 94.4 C 128.2 94.3 127.2 93.4 126.6 92.6 C 126 93.6 124.3 93.4 123.2 93.2 C 121.8 92.9 119.6 91 119.6 91 C 125.2 88.5 127.5 83.5 127.6 82.4 L 127.6 82.4 Z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: -2 }}
        animate={{ skewX: 2 }}
        transition={{
          ease: "linear",
          duration: 5,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.3,
        }}
        fill="#5d976e"
        d="M54.9 89.9c1.5 0 3-1.5 3-1.5s-2.4-1.6-3.3-3.2c-.7-1.2-1.1-3-1.1-3 1.2 0 2.3-1.5 2.3-1.5 0 .3-5.7-4.6-4.7-4.7 1.1-.2 1.6-1.2 1.6-1.2s-1.8-.8-2.2-1.3c-.6-.9-1-2.1-1.4-3.1-1.2 1.3-1.1 2.3-2 3.1l-2.8 1.8s.4.7 1.5 1.1c1 .3-4 4.4-4 4.4s1.8 1.4 2.7.9c0 0 0 1.2-1.5 3.7-.6.9-2 2.3-3 3.2.8 1.3 2 1.5 3.2 1.5-.8 3.6-5.2 6.3-5.2 6.3s2 1.4 3.2 1.5c.7 0 1.3-.3 2-.6 0 .3 1 1 1.6 1 .7 0 1-.3 1.7-.7 0 .6.8 1 1.4.8-.3 4.3 0 10.5.2 16.8h.6V98.6c.2.1 1 .6 1.6.5.4 0 .7.2 1-.8.5.4 1.4.9 2.2.8.9 0 1.6-.8 2.2-1.3.5.7 1.9.6 2.8.4 1.2-.2 3.1-1.6 3.1-1.6-4.7-2-6.7-6-6.7-6.7z"
      />
      <path
        fill="#5d976e"
        d="M41.6 149.4a.3.3 0 0 1-.2-.5v-.1a1 1 0 0 0-1.7 0c-.3.6-.6 1.3-.7 2a2.7 2.7 0 0 0 0 .9 10.8 10.8 0 0 1-1-4.5 10.4 10.4 0 0 1 .1-1.1l.2-1a10.9 10.9 0 0 1 2.1-4.6 2.9 2.9 0 0 0 1.2-1.2 2.2 2.2 0 0 0 .2-.6h-.2a.3.3 0 0 1-.3-.6l.2-.2.2-.2a1 1 0 0 0-.4-.3c-.4-.3-1 0-1.3.3-.4.4-.4 1-.3 1.5a3.9 3.9 0 0 0 .5 1.2 11 11 0 0 0-1.2 1.9 4.6 4.6 0 0 0-.3-2.1c-.2-.7-.7-1.2-1.1-1.7-.6-.7-1.6-.4-1.7.4l.2.2a.5.5 0 0 1-.2.8 4.6 4.6 0 0 0 .1.7 4.7 4.7 0 0 0 2.3 3h.2a11.2 11.2 0 0 0-.6 2.8 10.6 10.6 0 0 0 0 1.7 2.8 2.8 0 0 0-1-1.6c-.7-.6-1.8-.8-2.6-1.3a.5.5 0 0 0-.8.5 3 3 0 0 1 .4.2h.2a.5.5 0 0 1-.2 1 4.6 4.6 0 0 0 .8 1.2 4.7 4.7 0 0 0 3.4 1.5 11.2 11.2 0 0 0 .7 2.2h2.7a3 3 0 0 1-.7-.1l.6-.7.3-.4a4.5 4.5 0 0 0-.1-1.2zm39.2-33.3a.1.1 0 0 0 0-.3.4.4 0 0 1 .8-.2l.5.8a1.2 1.2 0 0 1 0 .4 4.6 4.6 0 0 0 0-2 4.5 4.5 0 0 0-.1-.4l-.2-.4a4.7 4.7 0 0 0-1.4-1.7 1.2 1.2 0 0 1-.6-.4 1 1 0 0 1-.1-.3.1.1 0 0 0 .1-.2v-.1h-.2a.4.4 0 0 1 .2-.2c.1-.2.4-.2.6 0l.2.5a1.7 1.7 0 0 1 0 .6 4.7 4.7 0 0 1 .6.7 2 2 0 0 1 0-1l.2-.8c.2-.3.7-.3.8 0v.1a.2.2 0 0 0 0 .4 2 2 0 0 1 .1.3 2 2 0 0 1-.7 1.4 4.8 4.8 0 0 1 .5 1.2 4.6 4.6 0 0 1 .2.7 1.2 1.2 0 0 1 .2-.8l1-.8a.2.2 0 0 1 .3.1 1.3 1.3 0 0 0 0 .2h-.2a.2.2 0 0 0 .2.4 2 2 0 0 1-.2.6 2 2 0 0 1-1.3 1 4.8 4.8 0 0 1 0 1l-1.2.2a1.3 1.3 0 0 0 .3-.1l-.3-.3-.2-.1a2 2 0 0 1 0-.5z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: 2 }}
        animate={{ skewX: -2 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.5,
        }}
        fill="#5d976e"
        d="M 175.1 100.007 C 176.6 100.007 178.1 98.507 178.1 98.507 C 178.1 98.507 175.7 96.907 174.8 95.307 C 174.1 94.107 173.7 92.307 173.7 92.307 C 174.9 92.307 176 90.807 176 90.807 C 176 91.107 170.3 86.207 171.3 86.107 C 172.4 85.907 172.9 84.907 172.9 84.907 C 172.9 84.907 171.1 84.107 170.7 83.607 C 170.1 82.707 169.7 81.507 169.3 80.507 C 168.1 81.807 168.2 82.807 167.3 83.607 L 164.5 85.407 C 164.5 85.407 164.9 86.107 166 86.507 C 167 86.807 162 90.907 162 90.907 C 162 90.907 163.8 92.307 164.7 91.807 C 164.7 91.807 164.7 93.007 163.2 95.507 C 162.6 96.407 161.2 97.807 160.2 98.707 C 161 100.007 162.2 100.207 163.4 100.207 C 162.6 103.807 158.2 106.507 158.2 106.507 C 158.2 106.507 160.2 107.907 161.4 108.007 C 162.1 108.007 162.7 107.707 163.4 107.407 C 163.4 107.707 164.4 108.407 165 108.407 C 165.7 108.407 166 108.107 166.7 107.707 C 166.7 108.307 167.5 108.707 168.1 108.507 C 167.8 112.807 168.1 119.007 168.3 125.307 L 168.9 125.307 L 168.9 108.707 C 169.1 108.807 169.9 109.307 170.5 109.207 C 170.9 109.207 171.2 109.407 171.5 108.407 C 172 108.807 172.9 109.307 173.7 109.207 C 174.6 109.207 175.3 108.407 175.9 107.907 C 176.4 108.607 177.8 108.507 178.7 108.307 C 179.9 108.107 181.8 106.707 181.8 106.707 C 177.1 104.707 175.1 100.707 175.1 100.007 L 175.1 100.007 Z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: 2 }}
        animate={{ skewX: -2 }}
        transition={{
          ease: "linear",
          duration: 3,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.1,
        }}
        fill="#0f5236"
        d="M 24.464 100.444 C 23.1 100.52 21.659 98.928 21.659 98.928 C 21.659 98.928 23.933 97.412 24.691 95.896 C 25.298 94.683 25.753 92.94 25.753 92.94 C 24.616 92.94 23.63 91.424 23.63 91.424 C 23.554 91.727 28.785 87.028 27.799 86.876 C 26.814 86.649 26.435 85.663 26.435 85.663 C 26.435 85.663 28.027 84.905 28.406 84.375 C 28.954 83.427 29.362 82.406 29.619 81.343 C 30.756 82.631 30.68 83.617 31.514 84.375 L 34.015 86.194 C 34.015 86.194 33.636 86.952 32.651 87.255 C 31.665 87.558 36.289 91.576 36.289 91.576 C 36.289 91.576 34.621 92.94 33.788 92.409 C 33.788 92.409 33.788 93.622 35.152 96.048 C 35.922 97.199 36.811 98.267 37.805 99.232 C 37.047 100.444 36.137 100.672 35 100.672 C 35.683 104.159 39.7 106.736 39.7 106.736 C 39.7 106.736 37.881 108.252 36.82 108.252 C 36.137 108.328 35.607 108.024 34.925 107.645 C 34.925 108.024 34.091 108.631 33.484 108.631 C 32.954 108.707 32.575 108.403 31.968 108.024 C 31.968 108.631 31.21 108.934 30.756 108.782 C 30.983 113.027 30.756 119.016 30.604 125.307 L 29.998 125.307 L 29.998 109.01 C 29.846 109.086 29.012 109.616 28.633 109.54 C 28.178 109.465 27.951 109.616 27.572 108.631 C 27.193 109.086 26.359 109.54 25.677 109.54 C 24.919 109.465 24.161 108.782 23.706 108.176 C 23.251 108.934 21.963 108.782 21.129 108.631 C 20.068 108.403 18.4 106.963 18.4 106.963 C 22.645 105.068 24.388 101.278 24.464 100.444 Z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: 3 }}
        animate={{ skewX: -3 }}
        transition={{
          ease: "linear",
          duration: 6,
          repeat: "Infinity",
          repeatType: "mirror",
          delay: 0.6,
        }}
        fill="#0f5236"
        d="M 97.2 87.4 C 95.4 87.5 93.5 85.4 93.5 85.4 C 93.5 85.4 96.5 83.4 97.5 81.4 C 98.3 79.8 98.9 77.5 98.9 77.5 C 97.4 77.5 96.1 75.5 96.1 75.5 C 96 75.9 102.9 69.7 101.6 69.5 C 100.3 69.2 99.8 67.9 99.8 67.9 C 99.8 67.9 101.9 66.9 102.4 66.2 C 103.123 64.951 103.662 63.603 104 62.2 C 105.5 63.9 105.4 65.2 106.5 66.2 L 109.8 68.6 C 109.8 68.6 109.3 69.6 108 70 C 106.7 70.4 112.8 75.7 112.8 75.7 C 112.8 75.7 110.6 77.5 109.5 76.8 C 109.5 76.8 109.5 78.4 111.3 81.6 C 112.316 83.119 113.489 84.527 114.8 85.8 C 113.8 87.4 112.6 87.7 111.1 87.7 C 112 92.3 117.3 95.7 117.3 95.7 C 117.3 95.7 114.9 97.7 113.5 97.7 C 112.6 97.8 111.9 97.4 111 96.9 C 111 97.4 109.9 98.2 109.1 98.2 C 108.4 98.3 107.9 97.9 107.1 97.4 C 107.1 98.2 106.1 98.6 105.5 98.4 C 105.8 104 105.5 111.9 105.3 120.2 L 104.5 120.2 L 104.5 98.7 C 104.3 98.8 103.2 99.5 102.7 99.4 C 102.1 99.3 101.8 99.5 101.3 98.2 C 100.8 98.8 99.7 99.4 98.8 99.4 C 97.8 99.3 96.8 98.4 96.2 97.6 C 95.6 98.6 93.9 98.4 92.8 98.2 C 91.4 97.9 89.2 96 89.2 96 C 94.8 93.5 97.1 88.5 97.2 87.4 L 97.2 87.4 Z"
      />
      <motion.path
        style={{ originX: 0.5, originY: 1, skewX: 2 }}
        animate={{ skewX: -2 }}
        transition={{
          ease: "linear",
          duration: 4,
          repeat: "Infinity",
          repeatType: "mirror",
        }}
        fill="#5d976e"
        d="M 124.988 89.9 C 126.488 89.9 127.988 88.4 127.988 88.4 C 127.988 88.4 125.588 86.8 124.688 85.2 C 123.988 84 123.588 82.2 123.588 82.2 C 124.788 82.2 125.888 80.7 125.888 80.7 C 125.888 81 120.188 76.1 121.188 76 C 122.288 75.8 122.788 74.8 122.788 74.8 C 122.788 74.8 120.988 74 120.588 73.5 C 119.988 72.6 119.588 71.4 119.188 70.4 C 117.988 71.7 118.088 72.7 117.188 73.5 L 114.388 75.3 C 114.388 75.3 114.788 76 115.888 76.4 C 116.888 76.7 111.888 80.8 111.888 80.8 C 111.888 80.8 113.688 82.2 114.588 81.7 C 114.588 81.7 114.588 82.9 113.088 85.4 C 112.488 86.3 111.088 87.7 110.088 88.6 C 110.888 89.9 112.088 90.1 113.288 90.1 C 112.488 93.7 108.088 96.4 108.088 96.4 C 108.088 96.4 110.088 97.8 111.288 97.9 C 111.988 97.9 112.588 97.6 113.288 97.3 C 113.288 97.6 114.288 98.3 114.888 98.3 C 115.588 98.3 115.888 98 116.588 97.6 C 116.588 98.2 117.388 98.6 117.988 98.4 C 117.688 102.7 117.988 108.9 118.188 115.2 L 118.788 115.2 L 118.788 98.6 C 118.988 98.7 119.788 99.2 120.388 99.1 C 120.788 99.1 121.088 99.3 121.388 98.3 C 121.888 98.7 122.788 99.2 123.588 99.1 C 124.488 99.1 125.188 98.3 125.788 97.8 C 126.288 98.5 127.688 98.4 128.588 98.2 C 129.788 98 131.688 96.6 131.688 96.6 C 126.988 94.6 124.988 90.6 124.988 89.9 Z"
      />
      <g transform="matrix(1, 0, 0, 1, -13.85566, -8.099747)">
        <path
          fill="#075269"
          d="M 98.782 134.7 C 102.082 134.2 105.282 135.4 108.682 135.7 C 112.005 135.879 115.309 135.08 118.182 133.4 C 120.195 132.257 122.685 132.374 124.582 133.7 L 124.782 133.8 C 126.382 134.8 128.382 134.8 130.182 134.8 L 148.682 135.3 C 148.682 135.3 153.182 136.3 153.782 138.5 C 154.482 140.8 152.982 143.2 151.082 144.4 C 149.082 145.6 146.682 145.9 144.382 146.1 C 129.632 147.575 114.759 147.273 100.082 145.2 C 97.382 144.8 94.082 144 93.182 141.4 C 92.182 138.3 95.482 135.1 98.782 134.7 Z"
        />
        <g>
          <path
            fill="#9a6767"
            d="M 125.688 119.698 C 125.214 121.161 123.643 121.963 122.181 121.487 L 121.947 121.4 C 120.621 120.835 119.946 119.35 120.392 117.979 C 120.463 117.767 120.558 117.564 120.678 117.374 C 121.798 115.549 124.473 115.621 125.494 117.504 C 125.86 118.18 125.931 118.977 125.688 119.707 L 125.688 119.698 Z"
          />
          <path
            d="M 120.418 118.117 C 120.798 118.307 121.239 118.031 121.576 117.755 L 121.455 117.858 C 122.008 118.48 122.349 119.26 122.431 120.087 C 122.561 120.899 122.569 121.728 122.734 122.541 C 122.851 123.368 123.262 124.125 123.891 124.674 C 124.772 125.34 125.991 125.314 127.079 125.124 L 127.122 125.115 C 126.839 124.267 126.73 123.371 126.803 122.48 C 127.083 123.374 127.568 124.19 128.219 124.864 C 128.941 124.674 129.646 124.426 130.327 124.121 C 130.768 123.923 131.243 123.655 131.373 123.188 C 131.502 122.722 131.226 122.238 130.846 121.936 C 130.466 121.642 130.008 121.487 129.576 121.271 C 129.144 121.055 128.72 120.752 128.556 120.312 C 128.168 119.275 129.317 118.109 128.885 117.089 C 128.669 116.588 128.133 116.312 127.641 116.096 C 126.877 115.772 126.091 115.501 125.291 115.284 C 124.596 115.052 123.859 114.972 123.131 115.051 C 122.414 115.163 121.706 115.595 121.446 116.269 C 121.014 116.415 120.548 116.571 120.272 116.934 C 119.995 117.288 120.012 117.91 120.418 118.117 Z"
            fill="#580c0c"
          />
          <path
            fill="#ffb6b6"
            d="M 116.427 130.627 C 116.984 130.169 117.82 130.326 118.172 130.955 L 122.233 130.229 L 121.386 132.199 L 117.671 132.579 C 116.861 132.965 115.936 132.33 116.007 131.435 C 116.032 131.119 116.183 130.828 116.427 130.627 Z"
          />
          <path
            fill="#629ac0"
            d="M 119.062 132.605 L 126.492 132.018 L 126.509 131.992 C 127.304 130.474 128.082 128.948 128.841 127.413 L 129.014 127.076 C 129.36 126.342 129.653 125.711 129.766 125.383 C 130.022 124.647 130.019 123.845 129.757 123.111 L 129.757 123.093 L 129.731 123.085 C 129.027 122.679 128.135 122.819 127.589 123.422 C 126.315 124.834 125.386 126.522 124.876 128.355 L 124.582 129.4 L 119.269 130.592 L 119.062 132.605 Z"
          />
          <path
            fill="#a0616a"
            d="M 105.81 146.617 L 107.546 147.455 L 105.369 152.103 L 104.194 151.533 L 105.81 146.617 Z M 113.257 151.308 C 112.916 151.524 112.57 151.731 112.22 151.93 L 112.211 151.93 L 112.073 151.965 L 111.883 151.464 L 111.797 151.231 L 111.667 150.902 L 111.442 150.298 L 110.242 147.127 L 112.108 146.669 L 112.177 146.954 L 112.211 147.093 L 112.868 149.736 L 112.997 150.28 L 112.997 150.298 L 113.084 150.617 L 113.153 150.902 L 113.231 151.213 L 113.257 151.308 Z"
          />
          <path
            fill="#1c2045"
            d="M 124.392 140.173 L 124.029 140.743 L 123.338 141.771 L 123.33 141.771 C 123.191 141.901 123.037 142.014 122.872 142.108 L 122.855 142.117 C 122.732 142.184 122.601 142.237 122.466 142.272 L 122.319 142.315 C 121.85 142.425 121.374 142.5 120.894 142.54 L 120.816 142.54 C 119.58 142.626 118.38 142.488 117.127 142.246 C 116.271 142.083 115.418 141.901 114.57 141.702 L 114.518 141.693 C 113.78 141.532 113.04 141.382 112.298 141.244 L 112.332 141.857 C 112.359 142.33 112.458 142.797 112.626 143.24 C 112.807 143.792 112.954 144.224 112.643 145.123 L 112.496 145.555 C 112.656 145.995 112.598 146.485 112.341 146.877 L 112.315 146.92 L 113.568 150.09 L 113.645 150.298 L 110.872 150.298 L 108.946 146.427 L 108.859 146.237 L 109.049 138.782 L 109.067 138.592 C 109.137 137.987 109.449 137.435 109.931 137.063 C 110.391 136.704 110.976 136.545 111.555 136.622 L 112.211 136.708 L 113.568 136.881 L 114.518 137.002 L 114.535 137.002 L 117.343 137.365 L 117.542 136.544 L 117.576 136.536 L 118.76 136.104 L 122.846 134.601 L 123.122 134.506 L 123.157 134.557 L 123.183 134.601 C 123.449 135.075 123.666 135.576 123.831 136.095 C 124.104 136.946 124.329 137.811 124.505 138.687 L 124.531 138.79 L 124.531 138.825 C 124.626 139.222 124.565 139.698 124.392 140.173 Z"
          />
          <path
            fill="#1c2045"
            d="M 124.565 137.426 C 124.55 137.37 124.533 137.315 124.513 137.261 C 124.377 136.85 124.219 136.446 124.038 136.052 C 123.831 135.603 123.589 135.119 123.295 134.601 L 122.846 133.814 L 122.587 133.4 C 122.587 133.391 122.587 133.382 122.587 133.374 L 122.552 133.339 L 118.509 133.875 L 117.006 134.082 L 116.92 134.825 L 116.894 135.015 L 116.833 135.525 L 116.755 136.19 L 114.509 135.896 L 112.211 135.603 L 110.976 135.439 C 110.396 135.364 109.811 135.526 109.352 135.888 C 108.872 136.258 108.561 136.806 108.488 137.408 L 108.479 137.598 L 107.313 140.363 C 106.614 142.014 106.169 143.762 105.991 145.546 L 105.136 148.276 L 105.257 148.293 L 107.071 148.466 L 107.546 148.501 L 108.6 146.963 C 108.722 146.789 108.838 146.61 108.946 146.427 C 109.121 146.124 109.277 145.809 109.412 145.486 L 111.693 140.06 L 112.211 140.147 C 113.012 140.279 113.781 140.386 114.518 140.466 C 116.902 140.726 118.976 140.752 120.824 140.527 C 121.343 140.469 121.841 140.386 122.319 140.276 L 122.837 140.155 L 122.967 140.13 C 123.691 139.941 124.27 139.398 124.505 138.687 C 124.645 138.281 124.666 137.843 124.565 137.426 Z M 105.741 151.3 L 104.471 150.686 L 104.272 150.591 L 103.952 151.369 L 101.724 151.861 C 101.325 151.938 101.159 152.417 101.424 152.724 C 101.482 152.79 101.555 152.841 101.637 152.872 L 103.814 153.753 L 104.505 153.131 L 104.333 153.969 L 105.153 154.306 L 106.052 151.447 L 105.741 151.3 Z"
          />
          <path
            fill="#2f2e41"
            d="M 113.645 150.298 L 113.568 150.298 L 113.568 150.09 L 113.645 150.298 Z"
          />
          <path
            fill="#1c2045"
            d="M 113.118 150.341 L 111.788 150.799 L 111.581 150.876 L 111.9 151.654 L 110.665 153.572 C 110.438 153.908 110.661 154.364 111.065 154.392 C 111.15 154.398 111.235 154.383 111.313 154.349 L 113.481 153.442 L 113.533 152.518 L 114 153.226 L 114.82 152.889 L 113.438 150.229 L 113.118 150.341 Z"
          />
          <path
            fill="#a0616a"
            d="M 107.849 116.053 C 107.911 116.641 108.49 117.029 109.058 116.865 L 110.976 119.629 L 111.624 118.005 L 109.706 115.629 C 109.459 114.941 108.559 114.778 108.086 115.336 C 107.92 115.533 107.841 115.788 107.866 116.044 L 107.849 116.053 Z"
          />
          <path
            fill="#471d56"
            d="M 109.499 117.573 L 111.27 116.675 L 115.045 121.59 L 121.835 123.603 C 123.267 124.025 123.964 125.643 123.287 126.972 C 122.686 128.147 121.249 128.614 120.073 128.018 L 112.963 124.389 L 109.499 117.573 Z"
          />
          <path
            fill="#471d56"
            d="M 124.22 124.726 C 123.528 124.381 122.993 123.759 122.751 122.653 L 121.628 122.566 L 117.913 126.212 C 115.969 128.588 117.377 130.221 117.464 130.462 L 116.79 133.564 L 116.937 134.601 L 117.015 134.912 L 124.695 138.92 L 124.047 133.538 L 124.651 128.873 C 125.343 126.713 125.628 126.506 124.22 124.726 Z"
          />
          <path
            fill="#a0616a"
            d="M 118.129 142.739 C 118.634 142.436 118.746 141.751 118.362 141.304 L 120.056 138.393 L 118.31 138.497 L 116.971 141.244 C 116.442 141.749 116.659 142.638 117.361 142.843 C 117.62 142.919 117.899 142.881 118.129 142.739 Z"
          />
          <path
            fill="#522163"
            d="M 118.812 140.596 L 117.248 139.387 L 120.09 133.875 L 119.019 126.869 C 118.79 125.388 119.967 124.066 121.464 124.121 C 122.779 124.168 123.809 125.268 123.77 126.584 L 123.511 134.566 L 118.812 140.596 Z"
          />
          <path
            fill="#ffb6b6"
            d="M 130.422 149.546 L 128.911 148.164 L 125.308 152.112 L 126.327 153.045 L 130.422 149.546 Z"
          />
          <path
            fill="rgb(28, 32, 69)"
            d="M 126.143 141.405 C 127.682 141.405 129.221 141.363 130.759 141.363 C 131.538 141.363 132.513 141.515 133.277 141.335 C 134.213 141.115 135.296 140.802 134.939 139.591 C 134.727 138.874 134.126 138.137 133.652 137.571 C 131.6 135.119 128.187 136.195 126.679 138.626 C 126.287 139.258 125.981 139.929 125.826 140.659 C 125.796 140.8 125.692 141.312 125.803 141.402 C 126.192 141.719 126.068 141.341 126.368 141.341"
          />
          <path
            fill="#629ac0"
            d="M 132.548 121.789 L 133.757 122.964 L 135.295 123.646 C 135.295 123.646 134.837 133.028 135.364 133.693 C 135.882 134.367 135.347 134.315 135.372 135.102 L 135.502 137.521 C 131.226 139.162 127.753 138.799 125.265 135.974 C 125.229 135.648 125.427 135.341 125.74 135.24 C 126.284 135.024 125.446 133.624 125.895 133.46 C 126.345 133.287 125.973 131.361 125.973 131.361 L 126.829 127.525 L 126.595 126.86 L 127.805 123.154 L 129.17 122.834 L 129.844 121.633 L 132.548 121.789 Z"
          />
          <path
            fill="#1c2045"
            d="M 125.351 151.542 L 125.636 151.801 L 127.183 152.371 L 127.364 153.382 C 128.073 154.028 128.123 155.127 127.476 155.835 L 127.442 155.878 L 124.142 152.863 L 125.351 151.542 Z M 133.066 141.209 L 132.176 139.499 C 132.238 137.763 133.747 136.436 135.476 136.596 C 136.426 136.683 137.308 137.261 137.627 138.868 C 138.431 143.015 128.729 151.213 128.729 151.213 L 127.044 150.09 L 127.01 148.959 L 127.995 148.397 L 128.038 147.438 L 128.971 147.032 L 133.066 141.209 Z"
          />
          <circle
            cx="808.6"
            cy="327.4"
            r="2.644"
            fill="#bfa8a8"
            transform="matrix(0.095846, -0.995396, 0.995396, 0.095846, -272.93686, 892.024839)"
          />
          <path
            fill="#1c2045"
            d="M 128.53 116.061 C 128.807 115.457 129.93 115.12 131.684 115.223 C 133.429 115.336 133.671 117.435 133.671 117.435 C 134.75 118.489 132.098 120.787 131.986 120.692 L 132.09 120.217 C 132.254 119.439 132.677 118.86 132.288 117.996 L 131.839 117.953 L 130.975 118.04 C 130.783 118.056 130.645 117.858 130.727 117.684 C 130.744 117.647 130.77 117.615 130.802 117.59 L 130.794 117.547 C 130.486 117.673 130.158 117.74 129.826 117.746 C 129.446 117.729 129.057 117.547 128.911 117.202 C 128.874 117.108 128.856 117.008 128.859 116.908 C 128.34 117.055 128.047 117.651 128.047 117.651 C 128.047 117.651 128.254 116.666 128.53 116.061 Z"
          />
          <path
            fill="#ffb6b6"
            d="M 127.934 148.518 L 129.645 147.386 L 132.582 151.853 L 131.424 152.613 L 127.934 148.518 Z"
          />
          <path
            fill="#1c2045"
            d="M 133.619 152.777 L 129.887 155.239 L 129.861 155.196 C 129.323 154.393 129.545 153.306 130.353 152.777 L 130.699 151.818 L 132.306 151.498 L 132.634 151.282 L 133.619 152.777 Z M 127.295 141.045 L 128.392 139.266 C 128.604 137.543 127.325 135.995 125.593 135.879 C 124.626 135.827 123.667 136.251 123.114 137.797 C 121.671 141.762 129.964 151.369 129.964 151.369 L 131.805 150.514 L 132.012 149.408 L 131.131 148.699 L 131.243 147.749 L 130.379 147.205 L 127.295 141.045 Z"
          />
          <path
            fill="#ffb6b6"
            d="M 131.399 138.428 C 131.46 137.711 132.154 137.223 132.85 137.408 L 135.156 133.996 L 135.977 135.974 L 133.662 138.911 C 133.367 139.759 132.265 139.97 131.678 139.29 C 131.472 139.052 131.371 138.741 131.399 138.428 Z"
          />
          <path
            fill="#6ea1c4"
            d="M 134.647 137.918 L 139.398 132.182 L 139.398 132.156 C 138.864 130.528 138.311 128.907 137.74 127.292 L 137.619 126.938 C 137.414 126.352 137.183 125.775 136.927 125.21 C 136.576 124.514 135.996 123.959 135.286 123.638 L 135.269 123.629 L 135.252 123.629 C 134.469 123.851 133.948 124.588 133.999 125.4 C 134.124 127.301 134.688 129.146 135.649 130.791 L 136.202 131.732 L 133.351 136.372 L 134.647 137.918 Z"
          />
          <path
            fill="rgb(28, 32, 69)"
            d="M 127.787 118.823 C 128.131 118.823 128.387 118.982 128.708 119.089 C 129.476 119.347 130.172 119.768 130.936 120.025 C 131.216 120.12 131.481 120.243 131.758 120.344 C 131.817 120.366 131.982 120.388 132.019 120.448 C 132.04 120.481 132.031 120.729 132.03 120.756 C 132.029 120.695 132.012 120.926 132.01 120.947 C 132.004 120.993 131.878 121.041 131.86 121.083 C 131.843 121.124 131.71 121.249 131.687 121.287 C 131.609 121.413 131.305 121.559 131.227 121.624 C 130.95 121.856 130.444 122.061 130.167 122.293 C 129.866 122.546 129.732 122.554 129.319 122.566 C 128.911 122.578 128.752 122.086 128.515 121.797 C 128.294 121.526 128.342 121.284 128.198 120.962 C 128.051 120.632 127.995 120.771 127.869 120.436 C 127.785 120.216 127.781 120.044 127.686 119.829 C 127.636 119.718 127.723 119.45 127.662 119.345 C 127.641 119.309 127.632 119.126 127.688 119.093 C 127.688 119.093 127.78 118.838 127.799 118.835"
          />
          <path
            fill="rgb(216, 216, 216)"
            d="M 128.955 119.988 C 129.329 120.255 129.242 120.695 130.331 120.431"
          />
        </g>
      </g>
    </motion.svg>
  );
};
