import React from "react";

const Button = ({ title, action, classes }) => {
  return (
    <button
      onClick={action}
      type="button"
      className={`inline-flex items-center px-6 py-3 border border-transparent text-base focus:border-transparent focus:ring-0 justify-center font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ${classes}`}
    >
      {title}
    </button>
  );
};

export default Button;
