import React, { useState } from "react";
import Button from "../components/common/Button";
import Macki from "../components/Macki";
import { Comets } from "../components/Macki/Comet";
import { Us } from "../components/Macki/Us";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
const Welcome = () => {
  const navigation = useNavigate();
  const [textAnim, setTextAnim] = useState(false);
  const onTextComplete = () => {
    setTextAnim(true);
  };
  const startMainAnimation = () => {
    if (textAnim) {
      console.log("zooom");
    }
  };
  return (
    <motion.div
      variants={homeVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ ...transition }}
      className="bg-background"
    >
      <AnimatePresence>
        <div className=" h-screen">
          <motion.div
            variants={parentVariants}
            initial="remove"
            animate="visible"
            className="w-full h-full flex justify-around items-center flex-col"
          >
            <Macki onAnimationComplete={onTextComplete} />
            {textAnim && (
              <motion.div
                layout
                variants={childVariants}
                key="animatedBox"
                className="h-full flex justify-evenly flex-col items-center"
              >
                <div className="relative sky ">
                  <Comets
                    style={{ position: "absolute", pointerEvents: "none" }}
                  />
                  <Us filter="drop-shadow(0px 12px 32px rgba(0,16,36,0.5))" />
                </div>
                <motion.div
                  className="w-full flex justify-center align-end"
                  layout
                  variants={childVariants}
                  key="animatedBox"
                  // animate={"show"}
                  // initial="hide"
                >
                  <Button
                    action={() => navigation("/code")}
                    title="Inditsd el"
                    classes={`w-2/3 `}
                  />
                </motion.div>
              </motion.div>
            )}
            {/* Animate bottom with flow svg  */}
            {/* https://codesandbox.io/s/framer-motion-morphsvg-example-dp7to?file=/src/App.tsx */}
            {/* https://designcode.io/react-animation */}
          </motion.div>
          {/* <div>{textAnim && "Hello"}</div> */}
        </div>{" "}
      </AnimatePresence>
    </motion.div>
  );
};

export default Welcome;
const parentVariants = {
  remove: {
    y: 50,
  },

  visible: {
    y: 0,
    transition: {
      type: "spring",
      duration: 4,

      // Parent props for orchestration:
      when: "beforeChildren", // children animations start when the parent animation is completed. Try with "afterChildren"
      staggerChildren: 0.3, // timing space between children animations
    },
  },

  hover: {
    scale: 1.1,
    transition: {
      repeat: 5, //or: Infinity,
      repeatType: "reverse",
      duration: 2,
    },
  },
};

const childVariants = {
  remove: {
    opacity: 0,
    y: 10,
  },

  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      mass: 0.4,
      damping: 6,
    },
  },
};
const transition = { duration: 0.3, ease: [0.6, 0.01, -0.05, 0.9] };

const homeVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 1 },
};

const fadeVariant = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
