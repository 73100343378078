import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Us } from "../components/Macki/Us";
import { Comets } from "../components/Macki/Comet";
import { useNavigate } from "react-router-dom";
import Szertlek from "../components/Macki/Szeretlek";
const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const page2Variant = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.79 } },
};

const imgVariant = {
  initial: { scale: 1.6, y: 340, x: 0, opacity: 0.8 },
  animate: { scale: 1.2, y: 340, x: 0, opacity: 1 },
};

const list = {
  visible: {
    opacity: 1,

    transition: {
      when: "beforeChildren",
      staggerChildren: 5,
    },
  },
  hidden: {
    opacity: 0,

    transition: {
      when: "afterChildren",
    },
  },
};
const item = {
  visible: {
    opacity: 1,

    transition: {
      repeat: 1,
      repeatType: "reverse",
      duration: 5,
      //   delay: 3,
    },
  },
  hidden: { opacity: 0 },
};

const letterVariant = {
  initial: { opacity: 0, y: "120%" },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 1, y: "120%" },
};

const Main = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <motion.main
        variants={page2Variant}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ ...transition }}
        className="bg-background relative h-screen overflow-hidden w-full flex justify-center items-center"
      >
        <motion.div
          variants={imgVariant}
          initial="initial"
          animate="animate"
          transition={{ duration: 1.1, ease: [0.91, 0.09, 0.47, 0.89] }}
          className="max-w-full h-full overflow-hidden pointer-events-none absolute"
          style={{ top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="relative sky ">
            <Comets style={{ position: "absolute", pointerEvents: "none" }} />
            <Us filter="drop-shadow(0px 12px 32px rgba(0,16,36,0.5))" />
          </div>
          {/* <img src={IMAGE_URL} alt="Woman leaning on wall" /> */}
        </motion.div>{" "}
        <div className="flex absolute top-0 justify-center flex-col items-center">
          <Szertlek />

          <motion.div
            className="mt-6 text-xl text-white font-bold w-full flex justify-center"
            variants={letterVariant}
            initial="initial"
            animate="animate"
            transition={{ delay: 8.5, ...transition }}
          >
            Kepzelj el valami szepet.
          </motion.div>
          <motion.div
            className="mt-6 text-xl text-white font-bold w-full flex justify-center text-center"
            variants={letterVariant}
            initial="initial"
            animate="animate"
            transition={{ delay: 10.5, ...transition }}
          >
            Csukd be a szemed. Szolok amikor kinyithatod.
          </motion.div>
        </div>
      </motion.main>
    </>
  );
};

export default Main;
