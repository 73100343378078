import Welcome from "./pages/Welcome";
import { AnimatePresence } from "framer-motion";
import { useLocation, Routes, Route, BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./AnimatedRoutes";
function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default App;
